<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
        :title="$t('page.headOffice.title')"
        @edit="$emit('edit')"
        :class="{ 'mb-5': isViewModeCreate }"
    >
      <p
          v-if="isQuebec"
          class="has-text-justified"
      >
        {{ $t('page.headOffice.descriptionQuebec') }}
      </p>
      <p
          v-if="isFederal"
          class="has-text-justified"
      >
        {{ $t('page.headOffice.descriptionFederal') }}
      </p>
      <b-button
          type="is-text is-anchor"
          @click="useFounderAddress"
      >
        {{ $t('page.headOffice.useFounderAddress') }}
      </b-button>
    </page-title>
    <section-address
        ref="headOfficeAddress"
        parent-path="$.headOffice"
        quebec-only
        :model="model"
        @validate="validate"
    >
    </section-address>
  </fieldset>
</template>

<script>
  import { mapGetters } from 'vuex'
  import page from '@/mixins/page'
  import SectionAddress from './SectionAddress'

  export default {
    name: 'PageHeadOffice',
    mixins: [
      page('headOffice')
    ],
    components: {
      SectionAddress
    },
    computed: {
      ...mapGetters('form', [
        'isFederal',
        'isQuebec'
      ])
    },
    methods: {
      useFounderAddress () {
        document.activeElement?.blur()
        const addressFields = ['address', 'city', 'province', 'postalcode']
        addressFields.forEach(key => {
          this.$set(this.model, key, this.storedModel.founder[key])
        })
        this.validate()
      },
      validate () {
        const addressErrors = this.$refs.headOfficeAddress.validate()
        if (addressErrors?.length)
          this.addError('headOfficeAddress')
        else
          this.removeError('headOfficeAddress')
      }
    }
  }
</script>
